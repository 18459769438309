import React from 'react';
import css from './style.module.scss';
import cls from '../../util/cls';
import parse from 'html-react-parser';

export default function Prose ({
	El = 'div',
	className,
	children,
	small = false,
	isPage = false,
	...props
}) {
	return (
		<El className={cls(css.prose, className, {
			[css.small]: small,
			[css.isPage]: isPage,
		})} {...props}>
			{React.Children.map(children, (child, i) => {
				if (typeof child === 'string') {
					const parsed = parse(child);

					if (typeof parsed === 'string')
						return <p key={i}>{parsed}</p>;

					return parsed;
				}

				return child;
			})}
		</El>
	);
}
